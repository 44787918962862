@import url('https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css');
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'body';
  src: url('../static/fonts/Gellix-Medium.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'heading';
  src: url('../static/fonts/Gellix-SemiBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'heading', 'Helvetica', 'Arial', serif !important;
}

*,
body {
  font-family: 'body', Helvetica, Arial, serif !important;
  outline: none;
}

.login *,
body {
  font-family: 'body', Helvetica, Arial, sans-serif !important;
}

.PhoneInputInput {
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 12px;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(51, 51, 51, 1);
}

.srcollTopics::-webkit-scrollbar {
  display: none;
}

.srcollTopics {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}
.onboarding .PhoneInputInput {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: #000;
  background-color: initial;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidden-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* css config for package `react-quill` */
.ql-container {
  font-size: 1rem !important;
}

.zammad-form-control {
  outline: none;
  border-radius: 8px;
  border: 1px solid #a3a3a3;
  padding: 8px 12px;
  color: darkslategray;
  font-size: 16px;
  line-height: 24px;
}

.zammad-form-group label {
  color: #273444;
}

.zammad-form .btn {
  color: white;
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 24px;
  background: #f00037;
  border: 1px solid #f00037;
  outline: none !important;
  border-radius: 8px;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  width: 100%;
}
